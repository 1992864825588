/*
Help Manager will listen to the help store in redux and determine which help modal to launch
*/
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { HelpPromptType, HelpTourType } from '../objectTypes';
import T1HelpModal from './T1HelpModal';
import T4HelpModal from './T4HelpModal';
import T3HelpModal from './T3HelpModal';
import T2HelpModal from './T2HelpModal';
import { autoLaunchHelper } from './HelpLogic/helpers';
import { segEventTrack } from '../utils/analytics/segment';
import { MyPlayerProps, PublicPlayerProps } from '../store/types/auth_svc';
import { V1_CLEAR_HELP, V1_SELECT_ACTION, V1_UPDATE_NO_HELP, V1_VIEW_ALL_PROMPTS, V1_VIEW_PROMPT } from '../store/actionsv1/types';
import T0HelpModal from './T0HelpModal';
import { player_getHelpPromptById, updatePlayer } from '../store/actionsv1/player';
import { BEEventApi } from 'be-components';
import { BEEventProps } from '../api/analytics/types';


type TourManagerType = { tour?:HelpTourType, active_step:number, tour_length:number }


const HelpManager = () => {
    const [ tour_manager, setTourManager ] = useState<TourManagerType>({ tour: undefined, active_step:0, tour_length:0 })
    const [ timer, setTourTimer ] = useState<any>(undefined)
    const [ active_prompt, setActivePrompt ] = useState<HelpPromptType | undefined>(undefined)
    const [ active_soft_prompt, setActiveSoftPrompt ] = useState<any>(undefined)

    const dispatch = useDispatch();
    
    const isAuthenticated:boolean = useSelector((state:any) => state.auth.isAuthenticated, (left, right) => left == right);
    const player:MyPlayerProps | undefined = useSelector((state:any) => state.player.player, (left, right) => left?.updated == right?.updated)
    const market_type:'FOR_MONEY'|'FREE' = useSelector((state:any) => state.auth.market_type, (left, right) => left == right);
    const current_route: undefined | { route:string, stack_key:string, key:string, params?:any } = useSelector((state:any) => state.util.current_route, (left, right) => {
        if(left?.key != right?.key){ return false }
        if(left?.route != right?.route){ return false }
        return true
    })
    const help_soft_prompts:{ id:string, client_native_id?:string, prompt?:string, body?:string }[] = useSelector((state:any) => state.util.help_soft_prompts, (left, right) => left == right);


    const help: {
        initial_id:string,
        view_type: string,
        help_tour?: HelpTourType,
        help_prompt?:HelpPromptType,
        tour_prompts: HelpPromptType[],
        active_tour_prompt: number,
        selected_action_type?:string
    } | undefined = useSelector((state:any) => state.util.help, (left, right) => left?.initial_id == right?.initial_id);
    const tooltipLocation = { x: 5, y: 5, height:200, width: 200 }
    const tip_direction = 'bottom'

    useEffect(() => {
        if(!current_route){ return }
        autoLaunchHelper(current_route.route, market_type, isAuthenticated, player)
    },[current_route, market_type, isAuthenticated, player])

    useEffect(() => {
        if(help_soft_prompts.length == 0){ return setActiveSoftPrompt(undefined) }
        setActiveSoftPrompt(help_soft_prompts[0])
    },[help_soft_prompts])

    useEffect(() => {
        if(!active_prompt){ return }
        let ev:BEEventProps = {
            event_name:'view_prompt',
            event_data:{
                help_prompt_id:active_prompt.help_prompt_id,
                client_native_id: active_prompt.client_native_id
            },
            level: 1
        }
        BEEventApi.saveEvent(ev)
        //segEventTrack({ event: 'View Prompt', properties : { help_prompt_id:active_prompt.help_prompt_id, prompt_title:active_prompt.title }})
    },[active_prompt])

    useEffect(() => {
        loadHelpData()
    },[help?.initial_id])

    useEffect(() => {
        if(!help?.tour_prompts || !tour_manager?.tour || tour_manager.tour_length === 0){ return } //Ignore since there is no tour
        let prompt = help.tour_prompts.find(p => p.tour_priority == tour_manager.active_step)
        if(!prompt){ return } //There is no prompt for this
        setActivePrompt(prompt)
    },[tour_manager.active_step])

    const loadHelpData = () => {
        if(!help){ return clearHelpData() }
        if(help.view_type === 'tour' ){ return loadTourData() }
        if(help.view_type === 'prompt'){ return setActivePrompt(help.help_prompt) }
    }

    const loadTourData = () => {
        if(!help?.tour_prompts || !help.help_tour || help.tour_prompts.length === 0){ return } //There were no prompts included with this tour
        setTourManager({ tour: help.help_tour, active_step:1, tour_length:help.tour_prompts.length })
        if(help.help_tour.carousel_change_seconds > 0){
            setTourTimer(setInterval(() => {
                setTourManager(prevState => {
                    if(prevState.active_step + 1 > prevState.tour_length){ return { ...prevState, active_step:1 } }
                    return { ...prevState, active_step:prevState.active_step + 1 }
                })
            }, help.help_tour.carousel_change_seconds * 1000))
        }
    }

    const handleTourChange = (active_step:number) => {
        if(!tour_manager?.tour){ return }
        return setTourManager({ ...tour_manager, active_step })
    }

    const clearHelpData = () => {
        setTourManager({ tour: undefined, active_step:0, tour_length:0 })
        setActivePrompt(undefined)
        dispatch({ type: V1_CLEAR_HELP })
        if(timer){ setTourTimer(clearInterval(timer)) }
    }

    if(!active_prompt && active_soft_prompt){
        return (
            <T0HelpModal 
                noHelp={() => {
                    dispatch({ type: V1_VIEW_ALL_PROMPTS })
                    if(!player){ return }
                    dispatch(updatePlayer({ ...player, guide_me: false }))
                }}
                full_screen={active_soft_prompt.full_screen}
                actions={active_soft_prompt.actions}
                is_authenticated={isAuthenticated}
                client_native_id={active_soft_prompt.client_native_id} 
                prompt={active_soft_prompt.prompt}
                body={active_soft_prompt.body}
                onClose={() => {
                    dispatch({ type: V1_VIEW_ALL_PROMPTS })
                }}
                onHelp={(client_native_id:string) => {
                    dispatch({ type:V1_VIEW_PROMPT, id: active_soft_prompt.id })
                    if(!client_native_id || client_native_id == ''){ return } //There is no help to get
                    dispatch(player_getHelpPromptById(client_native_id))
                }}
            />
        )
    }

    if(!help || !active_prompt){ return (<></>) }



    switch(active_prompt.prompt_type){
        case 't1': 
            return (
                <T1HelpModal isAuthenticated={isAuthenticated} help_prompt={active_prompt} is_tour={tour_manager.tour?true:false} tour_length={tour_manager.tour_length} onChangeTour={handleTourChange} clearHelp={() => clearHelpData()}/>
            )
        case 't2':
            return (
                <T2HelpModal isAuthenticated={isAuthenticated} onAction={(action_type) => dispatch({ type: V1_SELECT_ACTION, client_native_id:active_prompt.client_native_id, action_type })} help_prompt={active_prompt} is_tour={tour_manager.tour?true:false} tour_length={tour_manager.tour_length} onChangeTour={handleTourChange} clearHelp={() => clearHelpData()}/>
            )
        case 't3':
            return (
                <T3HelpModal help_prompt={active_prompt} is_tour={tour_manager.tour?true:false} tour_length={tour_manager.tour_length} onChangeTour={handleTourChange} tooltipLocation={tooltipLocation} tip_direction={tip_direction} clearHelp={() => clearHelpData()} />
            )
        case 't4':
            return (
                <T4HelpModal help_prompt={active_prompt} tooltipLocation={tooltipLocation} tip_direction={tip_direction} clearHelp={() => clearHelpData()}/>
            )
        default: return (<></>)
    }
    return (<></>)
    
}


export default HelpManager